import { removeTrailingSlash } from "./navigation";
export const getLocaleValue = (locale, defaultLocale)=>{
    return locale == defaultLocale ? "" : locale;
};
export const getLocalizedPath = (locale, defaultLocale)=>{
    const localeParam = getLocaleValue(locale, defaultLocale);
    return localeParam == "" ? "" : `/${localeParam}`;
};
export const getLocalizedUrl = (rootUrl, locale, defaultLocale)=>{
    return `${removeTrailingSlash(rootUrl)}${getLocalizedPath(locale, defaultLocale)}`;
};
export const getPathWithoutCurrentLocale = (pathName, currentLocale)=>{
    return pathName == `/${currentLocale}` ? "/" // pathname == /en or /fr
     : pathName.startsWith(`/${currentLocale}/`) ? pathName == null ? void 0 : pathName.replace(`/${currentLocale}/`, "/") // /en/ or /fr/ as 1st path part
     : pathName // no /en/ /fr/ found in path first part: don't replace other occurences
    ;
};
export const getLanguages = (rootUrl, path, locales, defaultLocale)=>{
    const languages = locales.map((locale)=>{
        return {
            locale: locale,
            url: `${getLocalizedUrl(rootUrl, locale, defaultLocale)}${path}`
        };
    });
    // add x-default locale
    languages.push({
        locale: "x-default",
        url: `${getLocalizedUrl(rootUrl, defaultLocale, defaultLocale)}${path}`
    });
    return languages;
};

import { NavbarItem } from '@astrolab/web/common/Navbar/NavbarItem'

/* Routes */

// Nextra
export const nextraPages = [
  '/investing',
  '/investing/.*',
  '/guides',
  '/guides/.*',
  '/blog',
  '/blog/.*',
  '/about',
  '/about/.*',
]

// Trigger hosting flag pages
export const hostingFlagPages = [
  '/trading-bot',
  '/guides',
  '/guides/.*',
  '/tools/scalping-signals',
  '/tools/triangular-arbitrage-crypto',
]

/* Navbar */
export const mainNavbarItems: Array<NavbarItem> = [
  {
    label: 'features.title',
    children: [
      {
        href: '/features/crypto-basket',
        label: 'features.index',
        localized: true,
      },
      {
        href: '/features/tradingview-bot',
        label: 'features.tradingview-bot',
        localized: true,
        forcedLocale: true,
      },
      {
        href: '/blog/smart-dca-making-of',
        label: 'features.dca-bot',
        localized: true,
        forcedLocale: true,
      },
      {
        href: '/features/ai-trading-bot',
        label: 'features.ai-bot',
        localized: true,
      },
    ],
  },
  {
    label: 'resources.title',
    children: [
      {
        label: 'resources.guides',
        href: '/investing/introduction',
        localized: true,
        forcedLocale: true,
      },
      {
        label: 'resources.blog',
        href: '/blog',
        localized: true,
        forcedLocale: true,
      },
      {
        label: 'resources.converter-tool',
        href: '/tools/converter',
        localized: true,
      },
      {
        label: 'resources.chatgpt-tool',
        href: '/tools/crypto-prediction',
        localized: true,
      },
      {
        label: 'resources.ranking-tool',
        href: '/tools/crypto-ranking',
        localized: true,
      },
      {
        label: 'company.about-us',
        href: '/about',
        localized: true,
        forcedLocale: true,
      },
      {
        label: 'resources.feedback',
        href: 'https://feedback.octobot.cloud/cloud',
        externalLink: true,
        noFollow: true,
      },
    ],
  },
  {
    label: 'pricing',
    href: '/pricing',
    localized: true,
    externalLink: false,
  },
]
export const tradingBotNavbarItems: Array<NavbarItem> = [
  {
    label: 'resources.title',
    children: [
      {
        label: 'resources.blog',
        href: '/blog',
        localized: true,
        forcedLocale: true,
      },
      {
        label: 'resources.converter-tool',
        href: '/tools/converter',
        localized: true,
      },
      {
        label: 'resources.chatgpt-tool',
        href: '/tools/crypto-prediction',
        localized: true,
      },
      {
        label: 'resources.ranking-tool',
        href: '/tools/crypto-ranking',
        localized: true,
      },
      {
        label: 'resources.arbitrage-tool',
        href: '/tools/triangular-arbitrage-crypto',
        localized: true,
      },
      {
        label: 'resources.scalping-signals',
        href: '/tools/scalping-signals',
        localized: true,
      },
      {
        label: 'resources.feedback',
        href: 'https://feedback.octobot.cloud/cloud',
        externalLink: true,
        noFollow: true,
      },
    ],
  },
  {
    label: 'company.title',
    children: [
      {
        label: 'company.about-us',
        href: '/about',
        localized: true,
        forcedLocale: true,
      },
      {
        label: 'company.for-business',
        href: process.env.NEXT_PUBLIC_OCTOBOT_BUSINESS_URL || '#',
        externalLink: true,
      },
    ],
  },
  {
    label: 'resources.guides',
    href: '/guides/octobot',
    localized: true,
    forcedLocale: true,
  },
]

export const toolsNavbarItems: Array<NavbarItem> = [
  {
    label: 'resources.title',
    children: [
      {
        label: 'resources.converter-tool',
        href: '/tools/converter',
        localized: true,
      },
      {
        label: 'resources.chatgpt-tool',
        href: '/tools/crypto-prediction',
        localized: true,
      },
      {
        label: 'resources.ranking-tool',
        href: '/tools/crypto-ranking',
        localized: true,
      },
      {
        label: 'resources.arbitrage-tool',
        href: '/tools/triangular-arbitrage-crypto',
        localized: true,
      },
      {
        label: 'resources.scalping-signals',
        href: '/tools/scalping-signals',
        localized: true,
      },
      {
        label: 'resources.blog',
        href: '/blog',
        localized: true,
        forcedLocale: true,
      },
      {
        label: 'resources.feedback',
        href: 'https://feedback.octobot.cloud/cloud',
        externalLink: true,
        noFollow: true,
      },
    ],
  },
  {
    label: 'company.title',
    children: [
      {
        label: 'company.about-us',
        href: '/about',
        localized: true,
        forcedLocale: true,
      },
      // {
      //   label: 'company.referral-terms',
      //   href: '/',
      //   localized: true,
      // },
    ],
  },
  {
    label: 'trading-bot',
    href: '/trading-bot',
    localized: true,
    externalLink: false,
  },
]

export const predictedCrypto = ['bitcoin', 'ethereum', 'solana']

export const discordLink = 'https://discord.com/invite/vHkcb8W'
export const telegramLink = 'https://t.me/joinchat/F9cyfxV97ZOaXQ47H5dRWw'

import { defaultCurrency } from "../types";
import { cloudHostingProductCategoriesSlug, serverlessHostingProductCategoriesSlug, getProfitabilityPeriodFallback, subscriptionProductCategoriesSlug, selfHostingProductCategoriesSlug, automationProductSlugs, productProfitabilityPeriods } from "./product.entity";
import { ProductRepository } from "./product.repository";
export const getProductPlan = (product, interval)=>{
    var _product_plans;
    return (_product_plans = product.plans) == null ? void 0 : _product_plans.find((plan)=>plan.interval == interval);
};
export const getProductPlanPrice = (plan, currency = defaultCurrency, fallbackCurrency = defaultCurrency)=>{
    var _plan_prices, _plan_prices1;
    const price = ((_plan_prices = plan.prices) == null ? void 0 : _plan_prices.find((price)=>price.currency == currency)) || ((_plan_prices1 = plan.prices) == null ? void 0 : _plan_prices1.find((price)=>price.currency == fallbackCurrency));
    return price ? {
        ...price,
        price: getProductPrice(price.price || 0),
        sale_price: getProductPrice(price.sale_price || 0)
    } : undefined;
};
export const getProductPrice = (productPrice)=>{
    return productPrice / 100 // TODO add currency converter
    ;
};
export const isCloudHostingProduct = (productWithPlan)=>{
    var _productWithPlan_product_categories;
    return cloudHostingProductCategoriesSlug.includes(((_productWithPlan_product_categories = productWithPlan.product_categories) == null ? void 0 : _productWithPlan_product_categories.slug) || "");
};
export const isCloudServerlessProduct = (productWithPlan)=>{
    var _productWithPlan_product_categories;
    return serverlessHostingProductCategoriesSlug.includes(((_productWithPlan_product_categories = productWithPlan.product_categories) == null ? void 0 : _productWithPlan_product_categories.slug) || "");
};
export const isProductFree = (productPrice)=>{
    return productPrice == undefined || productPrice.price == 0;
};
export const fetchSubscriptionProducts = async (supabase, categorySlugs = subscriptionProductCategoriesSlug)=>{
    const productRepository = new ProductRepository(supabase);
    const subscriptionProducts = await productRepository.getAll({
        categorySlugs
    });
    return subscriptionProducts || [];
};
export const fetchHostingProducts = async (supabase, hostingCategorySlugs = [
    cloudHostingProductCategoriesSlug,
    serverlessHostingProductCategoriesSlug,
    selfHostingProductCategoriesSlug
].flat())=>{
    const productRepository = new ProductRepository(supabase);
    const hostingProducts = await productRepository.getAll({
        categorySlugs: hostingCategorySlugs
    });
    if (hostingProducts) {
        hostingProducts.sort((a, b)=>{
            var _a_product_categories, _b_product_categories;
            return -(((_a_product_categories = a.product_categories) == null ? void 0 : _a_product_categories.slug) || "").localeCompare(((_b_product_categories = b.product_categories) == null ? void 0 : _b_product_categories.slug) || "");
        });
    }
    return hostingProducts ? hostingProducts : [];
};
export const fetchStrategies = async (supabase, limit = 3, productRepository)=>{
    const strategyProducts = await (productRepository || new ProductRepository(supabase)).getAllWithResults({
        categoryTypes: [
            "profile"
        ]
    }, limit);
    return strategyProducts || [];
};
export const fetchIndexes = async (supabase, limit = 3, productRepository)=>{
    const strategyProducts = await (productRepository || new ProductRepository(supabase)).getAllWithResults({
        categoryTypes: [
            "index"
        ]
    }, limit);
    return strategyProducts || [];
};
export const getCompatibleExchangeInternalNames = (product)=>{
    var _product_attributes;
    return ((_product_attributes = product.attributes) == null ? void 0 : _product_attributes.exchanges) || [];
};
export const getProfitabilityItems = (product, profitabilityNames = productProfitabilityPeriods)=>{
    var _product_results, _product_results1;
    const productProfitability = (product == null ? void 0 : (_product_results = product.results) == null ? void 0 : _product_results.reference_market_profitability) || (product == null ? void 0 : (_product_results1 = product.results) == null ? void 0 : _product_results1.profitability);
    if (!productProfitability) {
        return [];
    }
    return profitabilityNames.map((period)=>{
        return {
            period,
            value: (productProfitability == null ? void 0 : productProfitability[period]) || (productProfitability == null ? void 0 : productProfitability[getProfitabilityPeriodFallback(period)]) || 0
        };
    });
};
export const getProductMaxProfitability = (product)=>{
    const profitabilityItems = getProfitabilityItems(product);
    if (!profitabilityItems || profitabilityItems.length == 0) {
        return undefined;
    }
    return profitabilityItems.reduce((prev, current)=>prev.value > current.value ? prev : current, profitabilityItems[0]);
};
export const compareStrategyMaxProfits = (product1, product2)=>{
    var _getProductMaxProfitability, _getProductMaxProfitability1;
    return (((_getProductMaxProfitability = getProductMaxProfitability(product2)) == null ? void 0 : _getProductMaxProfitability.value) || 0) - (((_getProductMaxProfitability1 = getProductMaxProfitability(product1)) == null ? void 0 : _getProductMaxProfitability1.value) || 0);
};
export const sortStrategiesByProfits = (strategies)=>{
    if (!strategies) {
        return strategies;
    }
    return strategies.sort(compareStrategyMaxProfits);
};
export const isCompatibleStrategy = (product, selectedExchangeName)=>{
    var _product_attributes_exchanges, _product_attributes;
    return (product == null ? void 0 : (_product_attributes = product.attributes) == null ? void 0 : (_product_attributes_exchanges = _product_attributes.exchanges) == null ? void 0 : _product_attributes_exchanges.includes(selectedExchangeName)) || false;
};
export const isCompatibleIndex = (product, selectedExchangeName)=>{
    var _product_attributes_exchanges, _product_attributes;
    return (product == null ? void 0 : (_product_attributes = product.attributes) == null ? void 0 : (_product_attributes_exchanges = _product_attributes.exchanges) == null ? void 0 : _product_attributes_exchanges.includes(selectedExchangeName)) || false;
};
export const sortStrategiesByCompatibility = (strategies, selectedExchangeInternalName)=>{
    if (!strategies) {
        return strategies;
    }
    return strategies.sort((product1, product2)=>{
        return !isCompatibleStrategy(product2, selectedExchangeInternalName) ? -1 : 0;
    });
};
export const getProductRisk = (product)=>{
    var _product_attributes;
    return ((_product_attributes = product.attributes) == null ? void 0 : _product_attributes.risk) || undefined;
};
export const sortStrategiesByRiskLevel = (strategies, riskLevel)=>{
    if (!strategies) {
        return strategies;
    }
    return strategies.sort((product1, product2)=>{
        const risk1 = getProductRisk(product1);
        const risk2 = getProductRisk(product2);
        if (risk1 === riskLevel && risk2 !== riskLevel) {
            return -1;
        } else if (risk1 !== riskLevel && risk2 === riskLevel) {
            return 1;
        } else {
            return 0;
        }
    });
};
export const getProductByCategorySlug = (products, categorySlug)=>{
    return products.filter((product)=>{
        var _product_product_categories;
        return ((_product_product_categories = product.product_categories) == null ? void 0 : _product_product_categories.slug) == categorySlug;
    });
};
export const getPlanFromProduct = (product, planId)=>{
    var _product_plans_filter, _product_plans;
    return (product == null ? void 0 : (_product_plans = product.plans) == null ? void 0 : (_product_plans_filter = _product_plans.filter((productPlan)=>productPlan.id == planId)) == null ? void 0 : _product_plans_filter[0]) || undefined;
};
export const getProductMinimalFunds = (product)=>{
    var _product_attributes;
    return (product == null ? void 0 : (_product_attributes = product.attributes) == null ? void 0 : _product_attributes.minimal_funds) || [];
};
export const getMissingInProductMinimalFunds = (requiredMinimalFunds, portfolio)=>{
    // undefined for can't process and empty array when no missing funds
    const missingFunds = [];
    // return the total required portfolio when a minimal required funds is provided but when portfolio is empty or null
    if (portfolio == null || portfolio == undefined || portfolio.length == 0) {
        return requiredMinimalFunds;
    }
    if (requiredMinimalFunds && requiredMinimalFunds.length > 0) {
        for (const requiredMinimalFund of requiredMinimalFunds){
            const requiredValue = requiredMinimalFund.value || 0;
            const pfValue = portfolio.find((pfItem)=>pfItem.asset == requiredMinimalFund.asset);
            if (requiredValue <= 0) continue;
            if (pfValue == undefined || pfValue.available == null || pfValue.available < requiredValue) {
                // if the value doesn't exist or if the minimal funds are not reached
                missingFunds.push(requiredMinimalFund);
            }
        }
    }
    return missingFunds // return an empty array when no minimal required funds are provided
    ;
};
export const hasEnoughFundsOfCoin = (missingInProductMinimalFunds, coin)=>{
    return (missingInProductMinimalFunds == null ? void 0 : missingInProductMinimalFunds.find((productMinimalFundItem)=>productMinimalFundItem.asset == coin)) == undefined;
};
export const isPublic = (product)=>{
    return (product == null ? void 0 : product.visibility) == "public";
};
export const isPreview = (product)=>{
    return (product == null ? void 0 : product.visibility) == "preview";
};
export const isInPublicEarlyAccess = (product)=>{
    return (product == null ? void 0 : product.visibility) == "public_early_access";
};
export const isInPrivateEarlyAccess = (product)=>{
    return (product == null ? void 0 : product.visibility) == "private_early_access";
};
export const isPrivateEarlyAccessUnlocked = (product)=>{
    var _product_metadata_early_access_private_early_access, _product_metadata_early_access, _product_metadata;
    return product == null ? void 0 : (_product_metadata = product.metadata) == null ? void 0 : (_product_metadata_early_access = _product_metadata.early_access) == null ? void 0 : (_product_metadata_early_access_private_early_access = _product_metadata_early_access.private_early_access) == null ? void 0 : _product_metadata_early_access_private_early_access.unlocked;
};
export const isInRegisterEarlyAccess = (product)=>{
    return (product == null ? void 0 : product.visibility) == "register_early_access";
};
export const isPubliclyAvailable = (product)=>{
    return isPublic(product) || isInPublicEarlyAccess(product);
};
export const hasPriceLimitedCount = (productPlan)=>{
    var _productPlan_metadata;
    return (productPlan == null ? void 0 : (_productPlan_metadata = productPlan.metadata) == null ? void 0 : _productPlan_metadata.limited_count) != undefined;
};
export const hasDistribution = (product)=>{
    var _product_attributes;
    return ((_product_attributes = product.attributes) == null ? void 0 : _product_attributes.distribution) != undefined && product.attributes.distribution.length > 0;
};
export const areAutomationAllowed = (strategySlug)=>{
    return strategySlug != undefined && automationProductSlugs.includes(strategySlug);
};
export const getProductFiltersFromRiskLevel = (riskLevel)=>{
    switch(riskLevel){
        case "high":
            return {
                productCategories: [
                    "profile"
                ],
                subcategories: [
                    "ai-dca",
                    "popular"
                ],
                riskLevels: [
                    "High"
                ]
            };
        case "moderate":
            return {
                productCategories: [
                    "index"
                ],
                riskLevels: [
                    "Moderate"
                ]
            };
        case "low":
            return {
                productCategories: [
                    "index"
                ],
                riskLevels: [
                    "Low"
                ]
            };
    }
};
export const getProductFiltersFromInvestmentTarget = (investmentTarget)=>{
    switch(investmentTarget){
        case "well-known":
            return {
                productCategories: [
                    "index"
                ],
                subcategories: [
                    "top",
                    "by_marketcap"
                ],
                riskLevels: [
                    "Low"
                ]
            };
        case "other-crypto":
            return {
                productCategories: [
                    "index"
                ],
                subcategories: [
                    "thematic"
                ],
                riskLevels: [
                    "Moderate",
                    "High"
                ]
            };
        case "let-me-choose":
            return {
                productCategories: [
                    "profile"
                ],
                subcategories: [
                    "popular",
                    "classic-dca"
                ],
                riskLevels: [
                    "Moderate",
                    "High"
                ]
            };
        default:
            return {
                productCategories: [
                    "index"
                ],
                subcategories: [
                    "top",
                    "by_marketcap"
                ],
                riskLevels: [
                    "Low"
                ]
            };
    }
};
export const filterProducts = (products, filters)=>{
    return products.filter((product)=>{
        var _product_product_categories, _product_attributes, _product_attributes1;
        // category
        const productCategorySlug = (_product_product_categories = product.product_categories) == null ? void 0 : _product_product_categories.type;
        const isValidProductCategory = productCategorySlug ? filters.productCategories.includes(productCategorySlug) : true;
        // subcategory
        const productSubcategories = product == null ? void 0 : (_product_attributes = product.attributes) == null ? void 0 : _product_attributes.subcategories;
        const isValidProductSubCategory = filters.subcategories != undefined && productSubcategories != undefined ? productSubcategories.some((subcategory)=>{
            var _filters_subcategories;
            return (_filters_subcategories = filters.subcategories) == null ? void 0 : _filters_subcategories.includes(subcategory);
        }) : true;
        // risk level
        const productRiskLevel = product == null ? void 0 : (_product_attributes1 = product.attributes) == null ? void 0 : _product_attributes1.risk;
        const isValidProductRiskLevel = filters.riskLevels != undefined && productRiskLevel != undefined ? filters.riskLevels.includes(productRiskLevel) : true;
        return isValidProductCategory && isValidProductSubCategory && isValidProductRiskLevel;
    });
};
export const filterProductWithExchange = (products, exchangeName)=>{
    return exchangeName && exchangeName != "all" ? products.filter((product)=>{
        var _product_attributes_exchanges, _product_attributes;
        return (_product_attributes = product.attributes) == null ? void 0 : (_product_attributes_exchanges = _product_attributes.exchanges) == null ? void 0 : _product_attributes_exchanges.includes(exchangeName);
    }) : products;
};
export const filterProductWithSearchQuery = (products, searchQuery)=>{
    return searchQuery != undefined && searchQuery != "" ? products.filter((product)=>{
        var _product_attributes_coins, _product_attributes;
        return (_product_attributes = product.attributes) == null ? void 0 : (_product_attributes_coins = _product_attributes.coins) == null ? void 0 : _product_attributes_coins.includes(searchQuery.toUpperCase());
    }) : products;
};
export const filterProductWithExchanges = (products, exchangeNames)=>{
    return exchangeNames && exchangeNames != "all" ? products.filter((product)=>{
        var _product_attributes_exchanges, _product_attributes;
        return (_product_attributes = product.attributes) == null ? void 0 : (_product_attributes_exchanges = _product_attributes.exchanges) == null ? void 0 : _product_attributes_exchanges.some((productExchangeName)=>exchangeNames.includes(productExchangeName));
    }) : products;
};
export const filterProductWithRiskLevel = (products, riskLevel)=>{
    return riskLevel && riskLevel != "all" ? products.filter((product)=>{
        var _product_attributes;
        return (product == null ? void 0 : (_product_attributes = product.attributes) == null ? void 0 : _product_attributes.risk) == riskLevel;
    }) : products;
};
